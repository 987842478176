var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"offers-table",attrs:{"columns":_vm.columns,"totals-configuration":_vm.totalsConfiguration,"filters":_vm.baseFilters,"excluded-filters":_vm.baseExcludeFilters,"resource":_vm.$data.$constants.RESOURCES.RESOURCE_OFFERS,"resourceStore":'offer',"loading":_vm.loading},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearOfferListFilters,"row-clicked":function($event){return _vm.$router.push({ name: 'viewOffer', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(client)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.client.name)+" ")])]}},{key:"cell(project)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.project.name)+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('StatusBadge',{attrs:{"status":data.item.status.name}})]}},{key:"cell(total_cost)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" € ")]}},{key:"cell(total_sale_offer)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("numberToLocalString")(value))+" € ")]}},{key:"cell(actions)",fn:function(data){return [_c('b-row',{attrs:{"no-gutters":"","align-v":"center","align-h":"center"}},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"auto"}},[_c('b-link',{staticClass:"d-block text-indigo text-capitalize text-decoration-none",on:{"click":function($event){return _vm.handleCheckOfferLinkClick(data.item.id)}}},[_c('u',[_vm._v("Revisión oferta")])]),_c('b-link',{staticClass:"d-block text-indigo text-capitalize text-decoration-none",on:{"click":function($event){return _vm.handleDuplicateLinkClick(data.item.id)}}},[_c('u',[_vm._v("Duplicado")])])],1),_c('b-col',[_c('b-link',{staticClass:"d-inline-block text-indigo"},[(data.item.documentation.length)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Descargar documentación","icon":"DownloadIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDownloadClick(data.item.documentation)}}}):_vm._e()],1),_c('b-link',{staticClass:"px-1 d-inline-block text-indigo",attrs:{"to":{ name: 'viewOffer', params: { id: data.item.id} }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
              resource: _vm.$data.$constants.RESOURCES.RESOURCE_OFFERS,
              resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }),expression:"{\n              resource: $data.$constants.RESOURCES.RESOURCE_OFFERS,\n              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n            }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.deleteOffer(data.item.id, data.item.name)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)],1)],1)]}}])}),_c('OfferListFilters',{ref:"offer-list-filters",on:{"filters-submit":function($event){return _vm.$store.commit('offer/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }